<template>
    <section>
        <div class="row mx-0 border-bottom align-items-center p-1" style="height:44px;">
            <div class="col-6 f-18 f-500 text-general">
                Inventario de productos
            </div>
            <router-link v-if="$can('boton_productos_importar_stock')" :to="{name: 'admin.cedis.historial-importaciones',params:{id_cedis:id_cedi_ruta}}">
                <el-tooltip placement="bottom" content="Historial de actualización de inventario" effect="light">
                    <div class="btn-action border">
                        <i class="icon-history f-20 text-general" />
                    </div>
                </el-tooltip>
            </router-link>
            <el-tooltip placement="bottom" content="Descargar plantilla de inventario" effect="light">
                <div class="btn-action border ml-2">
                    <vue-excel-xlsx
                    class="btn"
                    :data="examplePlantilla"
                    :columns="fieldsPlantilla"
                    :filename="'plantilla_stock'"
                    :sheetname="'sheetname'"
                    >
                        <i class="icon-descargar f-18 text-general" />
                    </vue-excel-xlsx>
                </div>
            </el-tooltip>
            <el-tooltip v-if="$can('boton_productos_importar_stock')" placement="bottom" content="Importar inventario" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="subirArchivo()">
                    <i class="icon-importar f-18 text-general" />
                </div>
            </el-tooltip>
            <el-tooltip v-if="categoria !== null && $can('boton_productos_descargar_inventario')" placement="bottom" content="Descargar inventario" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="antesGenerar">
                    <i class="icon-descargar-inventario f-20 text-general" />
                </div>
            </el-tooltip>
            <el-tooltip v-if="$can('boton_productos_importar_stock')" placement="bottom" content="Escanear Codigo" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="escanearBarras()">
                    <i class="icon-barcode-scan f-20 text-general" />
                </div>
            </el-tooltip>
            <div class="col">
                <el-input v-model="buscar" size="small" placeholder="Buscar producto" class="br-20" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col px-0 cont-table custom-scroll overflow-auto" :style="`height:calc(100vh - ${$route.name == 'tendero.productos' ? '145' : $route.name == 'admin.tiendas.produccion.productos' ? '260' : '244'}px);`">
                <el-table
                id="cont-scroll"
                v-loading="loading"
                height="100%"
                :data="productos.filter(p => !buscar || p.nombre.toLowerCase().includes(buscar.toLowerCase()))" stripe
                >
                    <el-table-column prop="nombre" fixed="left" label="Producto" width="325" sortable>
                        <template slot-scope="scope">
                            <div class="row mx-0">
                                <el-tooltip class="item" effect="light" placement="top">
                                    <div slot="content" class="">
                                        <b>Categorías:</b>
                                        <p v-for="(data, idx) in scope.row.categorias" :key="idx">{{ data.nombre }}</p>
                                    </div>
                                    <div class="col">
                                        {{ scope.row.sku }} - {{ scope.row.nombre }} - {{ scope.row.presentacion }}
                                    </div>
                                </el-tooltip>
                                <el-tooltip v-if="scope.row.estado == 0" class="item" effect="light" content="Producto inactivo" placement="top">
                                    <i class="icon-eye-off f-20" />
                                </el-tooltip>
                                <el-tooltip v-if="scope.row.promocion" class="item" effect="light" content="Producto en promoción" placement="top">
                                    <i class="icon-sale text-danger f-20" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="carrito" label="Carrito" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="En carrito" placement="bottom">
                                <i class="icon-cart-outline text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sin_confirmar" label="Sin confirma" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Sin confirmar" placement="bottom">
                                <i class="icon-receipt text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="confirmar" label="Confirmado" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Confirmado" placement="bottom">
                                <i class="icon-ok-circled text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="alistando" label="Alistado" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Alistado" placement="bottom">
                                <i class="icon-cart-arrow-down text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="empacado" label="Empacado" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Empacado" placement="bottom">
                                <i class="icon-shopping text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="enviado" label="Enviado" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Enviado" placement="bottom">
                                <i class="icon-bike text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="disponible" label="Disponible" width="90" align="center" sortable>
                        <template slot="header">
                            <el-tooltip class="item" effect="light" content="Disponible" placement="bottom">
                                <i class="icon-storefront-outline text-general f-22" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total" label="Total" width="125" align="center" sortable>
                        <template slot-scope="scope">
                            <div class="row mx-3">
                                <p :class="`${scope.row.total < 0 ? 'text-danger':''}`">
                                    {{ scope.row.total }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="valor" label="Precio" width="150" sortable />
                    <el-table-column prop="fecha_valor" label="Fecha precio" width="150" sortable />
                    <el-table-column v-if="caso == 1" prop="valor_costo" label="Costo" width="150" sortable />
                    <el-table-column v-if="caso == 1" prop="fecha_costo" label="Fecha costo" width="150" sortable />
                    <el-table-column prop="rotacion" label="Rotacion general" width="180" align="center" sortable>
                        <template slot="header">
                            <p>Rotación general</p>
                            <el-tooltip class="item" content="Rotación diaria durante el último mes" effect="light" placement="bottom">
                                <i class="icon-info-circled-alt f-20 text-gr-general" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rotacion_mia" label="Rotacion cedis" width="180" align="center" sortable>
                        <template slot="header">
                            <p>Rotación cedis</p>
                            <el-tooltip class="item" content="Rotación diaria durante el último mes" effect="light" placement="bottom">
                                <i class="icon-info-circled-alt f-20 text-gr-general" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="venta_sin_stock" label="Venta sin Stock" width="155" align="center" sortable>
                        <template slot-scope="scope">
                            <div class="row mx-3">
                                <p>{{ scope.row.venta_sin_stock === 1 ? 'SI' : 'NO' }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="proveedor_favorito" label="Proveedor Favorito" width="150" align="center" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.proveedor_favorito != null ? scope.row.proveedor_favorito : '-' }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="proveedor_reciente" label="Proveedor Reciente" width="150" align="center" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.proveedor_reciente != null ? scope.row.proveedor_reciente : '-' }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modalEditar ref="modalEditar" @editar="inventario_productos" />
        <modal ref="modalConfirmar" titulo="Generar Excel" mensaje="¿Desear descargar el archivo con formato decimales con punto o coma?" no-aceptar>
            <slot>
                <div class="row justify-center py-3">
                    <el-radio v-model="tipo_decimal" :label="true">
                        Decimal con punto '.'
                    </el-radio>
                    <el-radio v-model="tipo_decimal" :label="false">
                        Decimal con coma ','
                    </el-radio>
                </div>
                <div class="row justify-center">
                    <el-checkbox v-model="radio">
                        Todas las categorias
                    </el-checkbox>
                </div>
            </slot>
            <template v-slot:excel>
                <export-excel
                :data="productos_excel"
                :fields="json_fields"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                worksheet="inventario"
                name="productos inventarios.xls"
                >
                    <button
                    v-loading="loading_excel"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10 border-0"
                    >
                        Descargar
                    </button>
                </export-excel>
            </template>
        </modal>
        <modalActualizarStock ref="modalActualizarStock" @update="inventario_productos" />
        <modal-escanear-barras ref="modalEscanearBarras" />
        <modal-subir-stock ref="modalImportarStock" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Productos from "~/services/productos/productos";

import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)
Vue.use(VueExcelXlsx)
export default {
    components: {
        modalEditar: () => import('./partials/modalEditarProducto'),
        modalActualizarStock: () => import('./partials/modalActualizarStock'),
        modalEscanearBarras: () => import('~/pages/cedis/partials/modalEscanearBarras'),
        modalSubirStock: () => import('~/pages/cedis/partials/modalImportarStock')
    },
    props: {
        categoria : {
            type: Number,
            default: -1
        }
    },
    data(){
        return {
            id_tienda_ruta: parseInt(this.$route.params.id_tienda),
            buscar: '',
            radio: '',
            productos: [],
            productos_excel: [],
            loading: false,
            loading_excel: false,
            tipo_decimal: true,
            examplePlantilla:[{barcode:'',cantidad:'',valor:'',proveedor:'',comentario:''}],
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
        caso(){
            let caso = 0
            switch (this.$usuario.rol_nombre){
            case 'tendero':
                caso = 4
                break;
            case 'admin':
                caso = 1
                break;

            default:
                break;
            }
            return caso
        },
        id_tienda(){
            let id_tienda = null
            if (this.caso == 4){
                id_tienda = this.$usuario.tienda.id
            } else if (this.caso == 1){
                id_tienda = null
            } else{
                id_tienda = this.id_tienda_ruta
            }

            return id_tienda
        },
        cedis(){
            let id_cedis = this.id_cedis
            if (this.$route.name == 'admin.cedis.productos'){
                id_cedis = this.$route.params.id_cedis
            }

            return id_cedis
        },
        fieldsPlantilla(){
            return[
                {
                    label: "barcode",
                    field: "barcode",
                },
                {
                    label: "sku",
                    field: "sku",
                },
                {
                    label: "cantidad",
                    field: "cantidad",
                },
                {
                    label: "valor",
                    field: "valor",
                },
                {
                    label: "proveedor",
                    field: "proveedor",
                },
                {
                    label: "comentario",
                    field: "comentario",
                },
                {
                    label: "fecha_vencimiento",
                    field: "fecha_vencimiento",
                },
            ]
        },
        json_fields(){
            return {
                'ID': 'id',
                'Producto': {
                    callback: (value) => {
                        return `${value.nombre} - ${value.presentacion}`;
                    }
                },
                'Sku': 'sku',
                'Barcode': 'barcode',
                'Confirmado': 'confirmar',
                'En carrito': 'carrito',
                'Sin confirmar': 'sin_confirmar',
                'Alistado': 'alistando',
                'Empacado': 'empacado',
                'Enviado': 'enviado',
                'Disponible': 'disponible',
                'Total': 'total',
                'Precio': 'valor',
                'Fecha precio': 'fecha_valor',
                'Costo': 'valor_costo',
                'Fecha costo': 'fecha_costo',
                'Rotación general': {
                    field: 'rotacion',
                    callback: (value) => {
                        return this.$options.filters.currency(value, '', 2, {
                            decimalSeparator: this.tipo_decimal ? '.' : ',',
                        });
                    }
                },
                'Rotación cedis': {
                    field: 'rotacion_mia',
                    callback: (value) => {
                        return this.$options.filters.currency(value, '', 2, {
                            decimalSeparator: this.tipo_decimal ? '.' : ',',
                        });
                    }
                },
                'Venta sin Stock': {
                    field: 'venta_sin_stock',
                    callback: (value) => {
                        return parseInt(value) === 1 ? 'SI' : 'NO'
                    }
                },
                'Proveedor Favorito': 'proveedor_favorito',
                'Proveedor Reciente': 'proveedor_reciente',
            }
        }
    },
    watch:{
        categoria(val){
            this.inventario_productos()
        },
        id_moneda(){
            this.inventario_productos()
        },
        id_cedis(){
            this.inventario_productos()
        },
        productos(val){
            this.$nextTick(() => {
                let cursor = document.querySelector('#cont-scroll');
                cursor.classList.add('cursor-pointer');
                let tabla = document.querySelector('#cont-scroll .el-table__body-wrapper');
                /* tabla.classList.add('cursor-pointer') */
                this.scrollInx(tabla);
            });
        }
    },
    mounted(){
        this.inventario_productos()
    },
    methods: {
        async editar(id){

            const {data} = await Productos.detalle(id)

            this.$refs.modalEditar.toggle(data.producto)
        },
        escanearBarras(){
            this.$refs.modalEscanearBarras.toggle();
        },
        subirArchivo(){
            this.$refs.modalImportarStock.toggle();
        },
        actualizarStock(producto){
            this.$refs.modalActualizarStock.toggle(producto)
        },
        antesGenerar(){
            this.$refs.modalConfirmar.toggle()
        },
        async inventario_productos(){
            try {
                if(this.categoria == null){
                    console.log('Por favor seleccione una categoria');
                    return
                }
                let params = {
                    id_categoria: this.categoria,
                    id_cedis: this.cedis,
                    id_moneda: this.id_moneda,
                    id_tienda:  this.caso == 1 ? (this.id_tienda_ruta ? this.id_tienda_ruta : null) : (this.id_tienda_ruta ? this.id_tienda_ruta :this.$usuario.tienda.id),
                    caso: this.id_tienda_ruta ? 4 : this.caso
                }
                this.loading = true
                const {data} = await Productos.inventario_productos(params)

                data.data.map(e=>{
                    e.valor = e.valor ? this.convertMoney(e.valor,e.idm_moneda) : '-----'
                    e.fecha_valor = this.formatearFecha(e.fecha_valor)
                    e.valor_costo = e.valor_costo ? this.convertMoney(e.valor_costo,e.idm_moneda) : '-----'
                    e.fecha_costo = this.formatearFecha(e.fecha_costo)
                    e.rotacion_tabla = this.agregarSeparadoresNumero(e.rotacion,1)
                    e.rotacion_mia_tabla = this.agregarSeparadoresNumero(e.rotacion_mia,1)
                    e.promocion = e.has_promocion
                })

                this.productos = data.data
                this.productos_excel = data.data
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async startDownload(){
            try {
                this.loading_excel = true
                if (this.radio){
                    let params = {
                        id_categoria: 0,
                        id_cedis: this.cedis,
                        id_moneda: this.id_moneda,
                        id_tienda:  this.caso == 1 ? (this.id_tienda_ruta ? this.id_tienda_ruta : null) : (this.id_tienda_ruta ? this.id_tienda_ruta :this.$usuario.tienda.id),
                        caso: this.id_tienda_ruta ? 4 : this.caso
                    }
                    const {data} = await Productos.inventario_productos(params)

                    if (data.data.length){
                        data.data.map(e=>{
                            e.valor = e.valor ? this.convertMoney(e.valor,e.idm_moneda) : '-----'
                            e.fecha_valor = this.formatearFecha(e.fecha_valor)
                            e.valor_costo = e.valor_costo ? this.convertMoney(e.valor_costo,e.idm_moneda) : '-----'
                            e.fecha_costo = this.formatearFecha(e.fecha_costo)
                            e.rotacion_tabla = this.agregarSeparadoresNumero(e.rotacion,1)
                            e.rotacion_mia_tabla = this.agregarSeparadoresNumero(e.rotacion_mia,1)
                            e.promocion = e.has_promocion
                        })
                        this.productos_excel = data.data
                    } else {
                        this.finishDownload()
                    }
                }else{
                    this.productos_excel = this.productos_excel.filter(p => !this.buscar || p.nombre.toLowerCase().includes(this.buscar.toLowerCase()))
                }
            } catch (error){
                console.log(error);
            }
        },
        finishDownload(){
            this.loading_excel = false
            this.$refs.modalConfirmar.toggle()
            this.tipo_decimal = true
            this.radio = false
        },
        scrollInx(element = null){
            if(!element) return
            const slider = element
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.cursor-pointer{
    cursor: grabbing !important; /* fallback if grab cursor is unsupported */
}
@media (max-width : 1000px) {
    .cont-table{
        height: calc(100vh - 169px) !important;
    }
}
/* @media (max-width: 768px){
    .cont-table{
        height: calc(100vh - 169px) !important;
    }
} */
</style>
